




@media screen and (max-width: 768px) {
    .footerTextDiv{
        flex: content;
        padding-left: 20px;
        padding-top: 20px;
    }

    .mainLogoDiv{
        cursor: pointer;
        width: 170px;
        height: 170px;
        overflow: hidden;
        padding-right: 10px;
    }
    
    .footerDiv{
        background-image: url("../assets/images/lower_bar.png");
        height : 540px !important;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        width: 100%;
    }

    .footerTextInfo{
        color: white;
        padding: 4px;
        font-family: "GothamBook";
        text-underline-offset: 0px;
        text-decoration: none;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .logoContainer{
        display: flex;
        flex-direction: row;
        padding-left:5px;
        padding-bottom:20px;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .socialMediaFooterDiv{
        flex: content;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .socialMediaFooterContainer{
        flex: content;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding-top: 30px;
    }

}

.footerDiv{
    background-image: url("../assets/images/lower_bar.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height : 330px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    width: 100%;
}

.mainLogoDiv{
    cursor: pointer;
    width: 170px;
    height: 170px;
    overflow: hidden;
}

.footerTextDiv{
    flex: content;
}

.socialMediaFooterDiv{
    flex: content;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.socialMediaFooterContainer{
    flex: content;
    align-items: center;
    align-content: center;
    justify-content: center;
}


.footerText{
    color: white;
    padding: 4px;
    font-family: "GothamBook";
    text-underline-offset: 0px;
    text-decoration: none;
    display: flex;
    cursor: pointer;
}


.socialMediaDiv{
    cursor: pointer;
    /*border-radius: 100%;*/
    width: 70px;
    height: 70px;
    /* padding-right: 20px; */
    margin-right: 8px;
    overflow: hidden;
}


.footerTextInfo{
    color: white;
    padding: 4px;
    font-family: "GothamBook";
    text-underline-offset: 0px;
    text-decoration: none;
    display: flex;
}

.logoContainer{
    display: flex;
    flex-direction: row;
    padding-left:5px;
    padding-bottom:20px;
}