.whiteSmallTextDiv {
    color: white;
    font-size:45px;
    width: 100%;
    font-family: "GothamBold";
    align-items: top;
    margin-top: 100px; 
    margin-left: 30px;
    text-shadow: 5px 5px 5px rgb(0 0 0 / 71%);
}

.greenSmallTextDiv {
    color: #1fff5f;
    font-size: 45px;
    width: '100%';
    font-family: "GothamBold";
    margin-left: 30px;
    text-shadow: 5px 5px 5px rgb(0 0 0 / 71%);
}

.whiteStepTextDiv1 {
    font-size:20px;
    width: 100%;
    font-family: "GothamBold";
    align-items: top;
    margin-top : 20px;
    color:white;
}

.whiteStepTextDiv2 {
    font-size:20px;
    width: 100%;
    font-family: "GothamBold";
    align-items: top;
    margin-top : 60px;
    color:white;
}

.whiteStepTextDiv3 {
    font-size:20px;
    width: 100%;
    font-family: "GothamBold";
    align-items: top;
    margin-top : 80px;
    color:white;
}

.whiteStepTextDiv4 {
    font-size:20px;
    width: 100%;
    font-family: "GothamBold";
    align-items: top;
    margin-top : 88px;
    color:white;
}

.stepImageDiv{
    /* margin-top:0px; */
    width: 34px;
    /* height: 9%; */
}

.stepImage1{
    width: 70px;
    height: 70px;
}

.stepImage2{
    width: 75px;
    height: 70px;
}

.stepImage3{
    width: 70px;
    height: 75px;
}

.stepImage4{
    width: 70px;
    height: 70px;
}


@media screen and (max-width: 770px) {

    .whiteSmallTextDiv {
        color: white;
        font-size:40px;
        width: 100%;
        font-family: "GothamUltraItalic";
        font-style: italic;
        align-items: top;
        margin-top: 230px; 
        margin-left: 20px;
    }

    .greenSmallTextDiv {
        color: #1fff5f;
        font-size: 30px;
        width: '100%';
        font-family: "GothamUltraItalic";
        font-style: italic;
        margin-left: 70px;
    }

    .whiteStepTextDiv1 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
    }

    .whiteStepTextDiv2 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
    }

    .whiteStepTextDiv3 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
        margin-top : 68px !important;
    }

    .whiteStepTextDiv4 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
        margin-top : 103px !important;
    }
    
    .stepImageDiv{
        /* margin-top:5px; */
        width: 33px;
        /* height: 3%; */
    }

    .stepImage1{
        width: 60px;
        height: 60px;
    }
    
    .stepImage2{
        width: 65px;
        height: 60px;
        margin-top: 5px;
    }
    
    .stepImage3{
        width: 60px;
        height: 65px;
        margin-top: 10px;
    }
    
    .stepImage4{
        width: 60px;
        height: 60px;
        margin-top: 15px;
    }
}


@media screen and (max-width: 900px) {
    
    .whiteStepTextDiv1 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
    }

    .whiteStepTextDiv2 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
    }

    .whiteStepTextDiv3 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
        margin-top : 75px ;
    }

    .whiteStepTextDiv4 {
        font-size:15px;
        width: 100%;
        font-family: "GothamBold";
        align-items: top;
        padding-left: 10px;
        margin-top : 105px;
    }
    
}




/* @media screen and (max-width: 950px) {
    

    .whiteStepTextDiv3 {
        margin-top : 55px;
    }

    .whiteStepTextDiv4 {
        margin-top : 80px;
    }
    
} */
