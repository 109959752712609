 .whiteTextDiv {
    color: white;
    font-size:90px;
    width: 100%;
    font-family: "GothamBold";
    align-items: top;
    margin-top: -120px;
    text-shadow: 5px 5px 5px rgb(0 0 0 / 71%);
    
  }
  
  .greenTextDiv {
    color: #1fff5f;
    font-size: 90px;
    width: '100%';
    font-family: "GothamBold";
    text-shadow: 5px 5px 5px rgb(0 0 0 / 71%);
  }

  .imageUpperBody{
    width: 100%;
    align-content: left;
  }

  .upperBodyDiv{
    margin-top: 15%;
  }

  
  @media screen and (max-width: 770px) {
    .whiteTextDiv {
        color: white;
        font-size:60px;
        width: 100%;
        font-family: "GothamUltraItalic";
        font-style: italic;
        align-items: top;
        margin-top: -100px;
        margin-left: -10px;
      }
      
      .greenTextDiv {
        color: #1fff5f;
        font-size: 60px;
        width: '100%';
        font-family: "GothamUltraItalic";
        font-style: italic;
        margin-left: -10px;
      }   

      .upperBodyDiv{
        margin-top: 50%!important;
      }
    
  }

  @media screen and (max-width: 820px) {
    .whiteTextDiv {
        color: white;
        font-size:55px;
        width: 100%;
        font-family: "GothamUltraItalic";
        font-style: italic;
        align-items: top;
        margin-top: -100px;
      }
      
      .greenTextDiv {
        color: #1fff5f;
        font-size: 55px;
        width: '100%';
        font-family: "GothamUltraItalic";
        font-style: italic;
      }   

      .upperBodyDiv{
        margin-top: 20%;
      }
    
  }

  @media screen and (max-width: 600px) {
    .whiteTextDiv {
        color: white;
        font-size:35px;
        width: 100%;
        font-family: "GothamUltraItalic";
        font-style: italic;
        align-items: center;
        align-content: center;
        margin-top: -55px;
        text-shadow: 3px;
        text-align: center;
        margin-left: -25px;
      }
      
      .greenTextDiv {
        color: #1fff5f;
        font-size: 54px;
        width: 100%;
        font-family: "GothamUltraItalic";
        font-style: italic;
        align-items: center;
        align-content: center;
        text-align: center;
        margin-left: 20px;
        margin-top: -3px;
      }   

      .upperBodyDiv{
        margin-top: 15%;
        align-items: center;
        align-content: center;
      }

      .imageUpperBody{
        width: 100%;
        height: 100%;
        align-content: center;
      }
    
  }


@font-face {
    font-family: "GothamBold";
    src: local("Gothambold"),
    url("../assets/fonts/Gotham-Bold.otf") format("truetype");
    font-weight: bold;
  }
  
  @font-face {
    font-family: "GothamBlack";
    src: local("Gothamblack"),
    url("../assets/fonts/Gotham-Black.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamBookItalic";
    src: local("Gothambookitalic"),
    url("../assets/fonts/Gotham-BookItalic.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamLight";
    src: local("Gothamlight"),
    url("../assets/fonts/Gotham-Light.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamThin";
    src: local("Gothamthin"),
    url("../assets/fonts/Gotham-Thin.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamThinItalic";
    src: local("Gothamthinitalic"),
    url("../assets/fonts/Gotham-ThinItalic.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamUltraItalic";
    src: local("Gothamultraitalic"),
    url("../assets/fonts/Gotham-UltraItalic.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamXLight";
    src: local("Gothamxlight"),
    url("../assets/fonts/Gotham-XLight.otf") format("truetype");
  }
  
  @font-face {
    font-family: "GothamXLightItalic";
    src: local("Gothamxlightitalic"),
    url("../assets/fonts/Gotham-XLightItalic.otf") format("truetype");
  }
  
  