

.charity-share-body{
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  text-align: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: auto;
}

.charity-share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: auto; 
}


.charity-share-content {
  background-color: rgba(255, 255, 255, 0); 
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  margin: 20px auto; 
}


.charity-share-content h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.charity-share-content p {
  font-size: 1.2em;
}

.charity-appleStoreIcon{
  width: 90%;
  margin: 15px;
}

.charity-playStoreIcon{
  width: 90%;
  margin: 15px;
}


.charity-inner-store-logos{
  flex-direction: row;
  overflow-y: auto; 
  display: flex;
}


@media (max-width: 768px) {
  .charity-share-container {
    background-position: center;
    background-size: cover;
    height: 100vh;
  }

  .charity-share-content {
    background-color: rgba(255, 255, 255, 0); 
    max-width: 95%;
  }
  .charity-appleStoreIcon{
    width: 80%;
    margin: 10px;
  }
  
  .charity-playStoreIcon{
    width: 80%;
    margin: 10px;
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards; /* Adjust duration to suit needs */
    display: flex;
    justify-content: center;
    gap: 20px; /* Spacing between logos */
    flex-direction: column;
  }

  .charity-inner-store-logos{
    flex-direction: column;
    /* overflow-y: auto;  */
    display: flex;
  }

}



.charity-accept-invite-button {
  background-color: #fff;
  color: #0b2545;
  border: 2px solid #0b2545;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 55px; /* Added for spacing */
}

.charity-accept-invite-button:hover {
  background-color: #0b2545;
  color: #fff;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1.3s forwards; /* Adjust duration to suit needs */
  display: flex;
  justify-content: center;
  gap: 20px; /* Spacing between logos */
  flex-direction: column;
}


.fade-out {
  animation: fadeOut 0.8s forwards; /* Adjust duration to 1.5 seconds for a slower fade-out */
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}




.charity-logo-container {
  align-items: center;
  align-content: center;
  justify-content: center;
}